import React from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon, IconNames } from 'ui/icon'

import { Container } from 'components/container'
import { SlickSlider } from 'components/slick-slider'
import { ExternalLink } from 'components/external-link'
import { useSlickSlide } from 'components/slick-slider/useSlickSlide'

import { useGlobalContext } from 'lib/context/global-context'

import siaLogo from 'brand-assets/illustrations/sia-logo-black.svg'
import siaLogoWhite from 'brand-assets/illustrations/logo/sia-logo.svg'
import realExperienceLogo from 'brand-assets/illustrations/multi-shapes.svg'
import realExperienceLogoWhite from 'brand-assets/illustrations/multi-shapes-white.svg'
import kfMembershipLogo from 'brand-assets/illustrations/airline.svg'
import kfMembershipLogoWhite from 'brand-assets/illustrations/airline-white.svg'
import unlockingInspirationLogo from 'brand-assets/illustrations/draw.svg'
import unlockingInspirationLogoWhite from 'brand-assets/illustrations/draw-white.svg'

import s from './styles.module.scss'

type Variant = 'home' | 'destination'
interface Item {
  iconName: keyof typeof logoMappings
  header: string
  subHeader: string
  text: string // legacy key, remove once deployed vi changes
  link: { label: string; url: string }
  label?: string
  url?: string
  variant: Variant
}
interface BookingWithUsProps {
  componentContent?: {
    items?: Item[]
  }
  className?: string
  variant: Variant
  bordered?: boolean
}

const logoMappings: Record<string, Record<string, any>> = {
  home: {
    SIALogo: siaLogoWhite,
    airlineLogo: kfMembershipLogoWhite,
    multiShapeLogo: realExperienceLogoWhite,
    drawLogo: unlockingInspirationLogoWhite,
    rocketLogo: 'rocket-launch',
    giftLogo: 'gift',
  },
  destination: {
    SIALogo: siaLogo,
    airlineLogo: kfMembershipLogo,
    multiShapeLogo: realExperienceLogo,
    drawLogo: unlockingInspirationLogo,
  },
}

const BookWithUsItem: React.FC<Item> = ({ iconName, header, subHeader, link, variant }) => {
  const { isMobileView } = useGlobalContext()

  const Logo = logoMappings[variant][iconName]

  const variantToClass: Record<Variant, Record<string, string>> = {
    home: {
      wrapper: 'ml-3',
      header: 'label-sm extra-bold',
      subHeader: 'label-sm text-grey-2 mt-1',
    },
    destination: {
      header: 'header-6 mt-2',
      subHeader: 'label-sm text-grey-2 mt-2',
    },
  }

  const shouldRenderLink = variant !== 'home' && link && link?.label && link?.url
  const Header = <h6 className={cn(variantToClass[variant]?.header, s.header)}>{header}</h6>
  const SubHeader = <p className={cn(variantToClass[variant]?.subHeader, s.subHeader)}>{subHeader}</p>
  const IconOrLogo =
    Logo &&
    (typeof Logo === 'string' ? (
      <Icon name={Logo as IconNames} size={isMobileView ? 'medium' : 'large'} className={s.logo} />
    ) : (
      <Logo className={cn(s.logo, { [s.large]: variant === 'home' })} />
    ))

  return (
    <div className={cn(s.item, s[`item-${variant}`])}>
      {IconOrLogo}
      {variant === 'home' ? (
        <div className={variantToClass[variant]?.wrapper}>
          {Header}
          {SubHeader}
        </div>
      ) : (
        <>
          {Header}
          {SubHeader}
        </>
      )}
      {!!shouldRenderLink ? (
        <ExternalLink href={link.url}>
          <Button
            variant={variant === 'destination' ? 'link-tertiary' : 'link-tertiary-light'}
            size="small"
            iconName="arrow-right"
            iconPosition="right"
            className="mt-2"
          >
            {link.label}
          </Button>
        </ExternalLink>
      ) : null}
    </div>
  )
}

const BookingWithUs = ({
  componentContent = {},
  className,
  variant = 'destination',
  bordered,
}: BookingWithUsProps) => {
  const { isMobileView, isTabletView } = useGlobalContext()
  const { items = [] } = componentContent
  const isHome = variant === 'home'

  const { setSliderRef, autoPlay } = useSlickSlide({
    autoPlayOnMobile: isMobileView,
    autoPlayOnTablet: isHome && isTabletView,
  })

  // eslint-disable-next-line react/no-array-index-key
  const Items = items?.map((item, index) => (
    <BookWithUsItem key={`${item?.header}-${index}`} {...item} variant={variant} />
  ))

  const sliderSettings = {
    speed: 500,
    autoplay: autoPlay,
    autoplaySpeed: 6000, // 6000 to match the animation duration of hero images
    pauseOnHover: isHome,
    infinite: autoPlay,
    arrows: false,
    dots: isHome,
    slidesToShow: isHome ? 1 : 1.25,
  }

  return (
    <Container className={cn(s.bookingWithUs, { [s.bordered]: bordered }, className)}>
      <div className={cn(s.items, s[`items-${variant}`])}>
        {isMobileView || (isHome && isTabletView) ? (
          <SlickSlider {...sliderSettings} wrapperClassName={s.sliderWrapper} ref={setSliderRef}>
            {Items}
          </SlickSlider>
        ) : (
          Items
        )}
      </div>
    </Container>
  )
}

export { BookingWithUs }
