import { useEffect, useRef, useState } from 'react'

import s from './styles.module.scss'

interface PlaceholderProps {
  search: {
    term?: string[]
    searchPlaceholder: string
  }
}

const Terms = ({ term }: { term: string[] }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const termRefs = useRef<HTMLSpanElement[]>([])

  useEffect(() => {
    if (!term?.length || term.length === 1) return

    const timeoutId = setInterval(() => setActiveIndex((curr) => (curr + 1) % term.length), 2000)

    return () => clearInterval(timeoutId)
  }, [term])

  const perDeg = 360 / term.length

  return (
    <span className={s.terms} style={{ width: termRefs.current[activeIndex]?.clientWidth || 'auto' }}>
      {term.map((item, index) => {
        const multiplier = (index - activeIndex + term.length) % term.length
        const deg = multiplier * perDeg

        const isNext = Math.abs(multiplier) === 1
        const isCurrentlyActive = index === activeIndex

        return (
          <span
            //@ts-ignore
            ref={(ref) => (termRefs.current[index] = ref)}
            key={item + index}
            style={{
              opacity: isCurrentlyActive ? 1 : 0,
              transform: `rotateX(${isNext ? -perDeg : deg}deg) translateZ(40px)`,
            }}
          >
            {item}
          </span>
        )
      })}
    </span>
  )
}

const TermPlaceholder = ({ search }: PlaceholderProps) => {
  const { searchPlaceholder, term } = search

  if (!term) return <span>{searchPlaceholder}</span>

  const pattern = /({{.*?}})/ // Regular expression to match the placeholder
  const parts = searchPlaceholder.split(pattern)

  return (
    <div className={s.termPlaceholder}>
      {parts?.map?.((part, index) => {
        if (part === '{{term}}') return typeof term === 'string' ? term : <Terms key={index} term={term} />

        return <span key={part}>{part}</span>
      })}
    </div>
  )
}

export { TermPlaceholder }
