import { useCallback, useEffect, useRef, useState } from 'react'

import { useGlobalContext } from 'lib/context/global-context'

const useSlickSlide = ({
  autoPlayOnMobile = false,
  autoPlayOnTablet = false,
}: {
  autoPlayOnMobile?: boolean
  autoPlayOnTablet?: boolean
}) => {
  const { isMobileView, isTabletView } = useGlobalContext()

  const [autoPlay, setAutoPlay] = useState(false)
  const sliderRef = useRef<any>()

  const setSliderRef = useCallback((node) => {
    sliderRef.current = node
  }, [])

  useEffect(() => {
    if ((isTabletView && autoPlayOnTablet) || (isMobileView && autoPlayOnMobile)) {
      setTimeout(() => {
        setAutoPlay(true)
        sliderRef?.current?.slickPlay?.()
      }, 1000)
    }
  }, [setAutoPlay, isMobileView, isTabletView, autoPlayOnMobile, autoPlayOnTablet])

  return { autoPlay, setSliderRef }
}

export { useSlickSlide }
