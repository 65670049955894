import React, { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Sizes } from 'ui/icon'

import { Grid } from 'components/grid'
import { Toast } from 'components/toast'
import { Container } from 'components/container'
import { ProductCard } from 'components/product-card'
import { PillsModifier } from 'components/modifiers/pills'
import { DynamicComponentProps } from 'components/dynamic-components/types'
import { Link } from 'components/link'
import { DestinationModal } from 'components/destination-modal'

import { getProductMerchandiseLabel } from 'page-modules/product/utils'

import useTranslation from 'lib/hooks/useTranslation'

import {
  noop,
  buildPath,
  productToEcommerceGtm,
  clearEcommerceToGa4,
  buildProductLinkSlugFromProduct,
} from 'lib/utils'
import { useGlobalContext } from 'lib/context/global-context'
import { ComponentModifier } from 'lib/@Types'

import { EVENTS } from 'lib/constants/events'
import { PRODUCT_ROUTE } from 'lib/constants/routes'
import { DATA_LAYER_EVENT } from 'lib/constants'

import s from './styles.module.scss'

interface CardListingProps extends DynamicComponentProps {
  loading?: boolean
  className?: string
  variant?: 'horizontal' | 'vertical' | 'horizontal-grey'
  autoVariantUpdate?: boolean
  mode?: 'bg-grey' | 'bg-white'
  columns?: number
  itemsPerPage?: number
  showRankLabel?: boolean
  showDestinationLabel?: boolean
  isSliderGrid?: boolean
  aspectRatio?: number
  collapseSize?: number
  chipIconSize?: Sizes | number
  enableDestinationModal?: boolean
  trackEvent?: TrackEventType
}

const CardListing = ({
  componentQueryData = {},
  componentContent = {},
  modifierManager,
  componentEventId = '',
  loading,
  className,
  variant = 'horizontal',
  columns = 2,
  itemsPerPage: pageSize,
  pageName,
  componentId,
  componentMetaType,
  componentType,
  componentRank,
  mode = 'bg-white',
  showRankLabel: showRankLabelProp,
  showDestinationLabel,
  isSliderGrid,
  aspectRatio,
  autoVariantUpdate = true, // setting it to false makes sure the variant is not getting updated to horizontal automatically
  collapseSize,
  chipIconSize,
  enableDestinationModal,
  trackEvent = noop,
}: CardListingProps) => {
  const itemsPerPage = pageSize || (columns === 4 ? 8 : 6)
  const { t, tp } = useTranslation('common')
  const { isMobileView } = useGlobalContext()
  const [showAll, setShowAll] = React.useState(false)
  const [toastVisible, setToastVisible] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState('')
  const [showDestinationModal, setShowDestinationModal] = useState(false)

  const { products: allProducts = [], productCount } = componentQueryData
  const { header, footer, link, subTitle = [], display_destination_name: showDestination } = componentContent

  const products = allProducts.slice(
    0,
    showAll || link?.action === 'redirect' ? allProducts.length : itemsPerPage
  )

  // note: link?.action === 'redirect' means this component has page size in graphql and we have to show cards = page size

  // Pass additional trackevents for country and destination page only
  const passAdditionalTrackEventData = pageName === 'country' || pageName === 'destination'

  const pillModifier = modifierManager?.list?.find?.((modifier) => modifier.type === 'pills') as
    | ComponentModifier<'pills'>
    | undefined

  const showModifiers = pillModifier?.isVisible ?? true
  const showRankLabel = showRankLabelProp && showModifiers && itemsPerPage < productCount

  const toastHandler = useCallback(
    (wishlisted: boolean, indexInList: number, isGuest?: boolean) => {
      if (isGuest) return
      const item = products[indexInList]
      const id = item?.productId
      const trackMeta = item?.trackMeta
      const attributeValue = {
        id,
        cardIndex: indexInList,
        cardType: EVENTS.PRODUCT,
        category: componentEventId,
        trackMeta,
        searchScore: item.searchScore,
        destinationId: item?.destinationId,
      }

      if (wishlisted) {
        setToastMessage(tp('msg.removedFromWishList'))
        trackEvent({
          attributeId: `${componentEventId}_${EVENTS.REMOVE}`,
          attributeType: `${EVENTS.WISHLIST}_${EVENTS.ATTRIBUTES_TYPE.ICON}`,
          attributeValue,
        })
      } else {
        setToastMessage(tp('msg.addedToYourWishlist'))
        trackEvent({
          attributeId: `${componentEventId}_${EVENTS.ADD}`,
          attributeType: `${EVENTS.WISHLIST}_${EVENTS.ATTRIBUTES_TYPE.ICON}`,
          attributeValue,
        })
        clearEcommerceToGa4()
        window?.dataLayer?.push({ event: DATA_LAYER_EVENT.ADD_TO_WISHLIST, ...productToEcommerceGtm(item) })
      }
      setToastVisible(true)
    },
    [componentEventId, products, tp, trackEvent]
  )

  const renderHeading = () => {
    if (!header) return null

    return (
      <div className={s.headingText}>
        {!Array.isArray(header) ? (
          <h3>{header}</h3>
        ) : (
          <>
            <h3>{header[0] || ''}&nbsp;</h3>
            <h3>{header[1] || ''}</h3>
          </>
        )}
      </div>
    )
  }

  const renderFooter = () => {
    const showFooterLink = link.isVisible ?? true
    return (
      <>
        {footer && <div className={s.seeMoreText}>{footer}</div>}

        {showFooterLink && link.action === 'redirect' && (
          <Link href={`/${link.url}`} passHref>
            <Button
              variant={'secondary'}
              size={isMobileView ? 'medium' : 'large'}
              className={s.seeAllCta}
              fluid={isMobileView}
              onClick={() =>
                trackEvent({ attributeId: componentEventId, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
              }
            >
              <span>{link.label}</span>
            </Button>
          </Link>
        )}
        {showFooterLink && link.action === 'expand' && (
          <Button
            size={isMobileView ? 'medium' : 'large'}
            variant={'secondary'}
            className={cn(s.showAllCta, { [s.seeLess]: showAll })}
            fluid={isMobileView}
            onClick={() => {
              setShowAll(!showAll)
              trackEvent({
                attributeId: `${componentEventId}_${showAll ? 'show_less' : 'show_all'}`,
                attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
              })
            }}
          >
            <span>{showAll ? t('action.showLess') : t('action.showAll')}</span>
          </Button>
        )}
      </>
    )
  }

  const onCardClick = (item: any, index: number) => {
    const cardIndex = index
    trackEvent({
      attributeId: componentEventId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
      attributeValue: {
        id: products?.[index]?.productId,
        cardIndex,
        cardType: 'product',
        trackMeta: products?.[index]?.trackMeta,
        searchScore: products?.[index]?.searchScore,
        destinationId: products?.[index]?.destinationId,
      },
      container: `${componentEventId}_products`,
    })
  }

  const onCardExposure = (items: any) => {
    trackEvent({
      attributeId: componentEventId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
      eventType: EVENTS.TYPE.EXPOSURE,
      attributeValue: {
        cardType: 'product',
        cards: items.map(({ data, indexInList }: { data: Product; indexInList: number }) => {
          return {
            id: data.productId,
            cardIndex: indexInList,
            trackMeta: data.trackMeta,
            searchScore: data.searchScore,
            uri: buildPath(PRODUCT_ROUTE, { productSlug: buildProductLinkSlugFromProduct(data) }),
            destinationId: data.destinationId,
            ...getProductMerchandiseLabel(data.productTags),
          }
        }),
        ...(passAdditionalTrackEventData && { componentId, componentMetaType, componentType, componentRank }),
      },
    })
  }

  const productList = useMemo(() => {
    if (loading)
      return Array.from({ length: itemsPerPage }).map((_, index) => ({
        loading,
        key: index,
        indexInList: index,
      }))

    return products.map((product: any, index: number) => {
      return {
        key: product.productId,
        variant: isMobileView && autoVariantUpdate ? 'horizontal' : variant,
        data: product,
        imageProps: { loading: 'lazy' },
        label: showRankLabel ? index + 1 : null,
        toastHandler,
        showDestination,
        indexInList: index,
      }
    })
  }, [
    autoVariantUpdate,
    isMobileView,
    itemsPerPage,
    loading,
    products,
    showDestination,
    showRankLabel,
    toastHandler,
    variant,
  ])

  const itemProps = useMemo(() => {
    const props = {
      showDestination: showDestinationLabel,
      fontSize: columns === 4 && aspectRatio === 1 ? 'large' : 'default',
    }

    if (aspectRatio) return { ...props, aspectRatio }

    if (isMobileView && (autoVariantUpdate || variant === 'horizontal') && columns === 1)
      return { ...props, imageSize: 'small' }
    if (isMobileView && variant === 'vertical') return { ...props, aspectRatio: 1 }

    return props
  }, [isMobileView, variant, showDestinationLabel, columns, autoVariantUpdate, aspectRatio])

  const handleCtaClick = useCallback(() => {
    trackEvent?.({
      attributeId: `${componentEventId}_show_all`,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })
    setShowAll(true)
  }, [componentEventId, trackEvent])

  const renderPillModifier = () => {
    if (showModifiers && !!pillModifier?.data?.length) {
      return (
        <PillsModifier
          modifier={pillModifier}
          variant={mode === 'bg-grey' ? 'dark' : 'light-ghost-bold'}
          size={isMobileView ? 'medium' : 'large'}
          iconSize={chipIconSize}
          componentEventId={componentEventId}
          onChange={(item) =>
            modifierManager?.update({
              current: item,
              type: 'pills',
              modifierId: pillModifier.modifierId,
            })
          }
          trackEvent={trackEvent}
        >
          {enableDestinationModal && isMobileView && (
            <Button
              className={s.seeAllDestinationBtn}
              onClick={onOpenDestinationModal}
              variant="link-tertiary"
              size="medium"
            >
              {t('t.seeAllDestinations')}
            </Button>
          )}
        </PillsModifier>
      )
    }

    return null
  }

  const onOpenDestinationModal = useCallback(() => setShowDestinationModal(true), [])
  const onCloseDestinationModal = useCallback(() => setShowDestinationModal(false), [])

  return (
    <Container className={cn(className, { [s.bgGrey]: mode === 'bg-grey' })}>
      <div className={s.sectionHeading}>
        {renderHeading()}

        {showModifiers && !!pillModifier?.data?.length ? (
          enableDestinationModal ? (
            <div className={s.pillDestinationContainer}>
              <div className={s.pillContainer}>{renderPillModifier()}</div>
              {enableDestinationModal && !isMobileView && (
                <Button
                  className={s.seeAllDestinationBtn}
                  onClick={onOpenDestinationModal}
                  variant="link-tertiary"
                  size="large"
                >
                  {t('t.seeAllDestinations')}
                </Button>
              )}
            </div>
          ) : (
            renderPillModifier()
          )
        ) : null}

        {subTitle.length > 0 && (
          <div className={s.subTitle}>
            {subTitle.map((subTitleText: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${subTitleText}-${index}`}>{subTitleText}</div>
            ))}
          </div>
        )}
      </div>

      <Grid
        className={cn(s.products, {
          [s._horizontal]: variant === 'horizontal',
          [s.broaderGap]: columns === 4 && aspectRatio === 1,
        })}
        columns={columns}
        loading={loading}
        items={productList}
        itemProps={itemProps}
        itemComponent={ProductCard}
        onClick={onCardClick}
        onExposure={onCardExposure}
        collapseSize={collapseSize}
        ctaText={t(
          collapseSize && allProducts.length >= itemsPerPage ? 'action.seeMore' : 'action.loadMore',
          {
            ns: 'common',
          }
        )}
        onCtaClick={handleCtaClick}
      />

      {((link && !collapseSize) || (link && collapseSize && showAll)) && (
        <div className={cn(s.seeMore)}>{renderFooter()}</div>
      )}
      {/* do not show this button when user clicks once to load all products */}
      {!link && isSliderGrid && allProducts.length > itemsPerPage && !showAll && (
        <div className={cn(s.seeMore)}>
          <Button
            className={s.loadMore}
            variant="link-tertiary"
            size={isMobileView ? 'medium' : 'large'}
            onClick={() => {
              setShowAll(true)
              trackEvent({
                attributeId: `${componentEventId}_${'show_all'}`,
                attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
              })
            }}
          >
            <span>{t('action.loadMore', { ns: 'common' })}</span>
          </Button>
        </div>
      )}
      {toastVisible && <Toast onClose={() => setToastVisible(false)} message={toastMessage} type="success" />}
      {enableDestinationModal && (
        <DestinationModal
          open={showDestinationModal}
          closeModal={onCloseDestinationModal}
          trackEvent={trackEvent}
          shouldAutoFocus={!isMobileView}
        />
      )}
    </Container>
  )
}

const TwoColumnsHorizontalCard = (props: CardListingProps) => {
  const { isMobileView } = useGlobalContext()
  return <CardListing {...props} variant="horizontal" columns={isMobileView ? 1 : 2} />
}

const ThreeColumnsVerticalCard = (props: CardListingProps) => {
  const { isMobileView, isTabletView } = useGlobalContext()
  return <CardListing {...props} variant="vertical" columns={isMobileView ? 1 : isTabletView ? 2 : 3} />
}

const FourColumnsVerticalCard = (props: CardListingProps) => {
  const { isMobileView, isTabletView } = useGlobalContext()
  return <CardListing {...props} variant="vertical" columns={isMobileView ? 1 : isTabletView ? 2 : 4} />
}

CardListing.TwoColumnsHorizontalCard = TwoColumnsHorizontalCard
CardListing.ThreeColumnsVerticalCard = ThreeColumnsVerticalCard
CardListing.FourColumnsVerticalCard = FourColumnsVerticalCard

export { CardListing }
