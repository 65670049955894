import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'

import { Image } from 'ui/image'

import { BookingWithUs } from 'components/book-with-us'
import { ExposureTracker } from 'components/exposure-tracker'
import { SearchModal } from 'components/search-modal'
import { useSearchModal } from 'components/search-modal/hooks'
import { TermPlaceholder } from 'components/term-placeholder'
import {
  SearchAutocomplete,
  SearchAutocompleteResults,
  SearchAutocompleteSeeMore,
} from 'components/search-autocomplete'
import { Container } from 'components/container'
import { InitDropdown } from 'components/search-autocomplete/init-dropdown'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import useHeader from 'lib/hooks/useHeader'
import useSearch from 'lib/hooks/useSearch'
import useTranslation from 'lib/hooks/useTranslation'

import { getAutoSuggestFilter, searchExposureOnEmptyResults } from 'lib/utils/search'
import { useGlobalContext } from 'lib/context/global-context'
import { buildPath, logError } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { SEARCH_ROUTE } from 'lib/constants/routes'
import { DATA_LAYER_EVENT } from 'lib/constants'

import { AUTO_SUGGEST_SEACH_QUERY } from 'gql/queries/search'

import s from './styles.module.scss'

interface HeroProps extends DynamicComponentProps {
  trackEvent?: TrackEventType
  id: 'hero'
}

const Hero = ({ componentContent = {}, componentEventId, trackEvent, id }: HeroProps) => {
  const router = useRouter()
  const [activeHeroIndex, setActiveHeroIndex] = React.useState(0)
  const HERO_IMAGE_ROTATION = 6000
  const DELAY_HERO_IMAGE_LOAD = HERO_IMAGE_ROTATION - 2000

  const { t } = useTranslation()
  const { isSearchModalOpen, openSearchModal, closeSearchModal, loader } = useSearchModal()
  const { isMobileView } = useGlobalContext()
  const { setWayPoint: searchInputWayPoint } = useHeader({
    mode: 'searchVisibility',
    defaultValue: false,
  })
  const { setWayPoint: scrollStartWayPoint } = useHeader({
    mode: 'transparency',
    defaultValue: true,
  })

  const {
    query,
    handleChange: doSearch,
    data,
    error,
    searching,
  } = useSearch({
    filter: () => getAutoSuggestFilter({}),
    searchQuery: AUTO_SUGGEST_SEACH_QUERY.query,
    onSearchQueryComplete: (queryData) => {
      // exposure event will be triggered by component if there are results
      searchExposureOnEmptyResults({
        query,
        destinations: queryData?.[AUTO_SUGGEST_SEACH_QUERY.queryName.destinations]?.destinations,
        products: queryData?.[AUTO_SUGGEST_SEACH_QUERY.queryName.products]?.products,
        trackEvent,
      })
    },
  })

  const { header, subHeader, items, search, images } = componentContent

  const handleSearchClick = () => {
    if (!isMobileView) return

    openSearchModal?.()
  }

  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setActiveHeroIndex((curr) => (curr + 1) % images?.length)
    }, HERO_IMAGE_ROTATION)

    return () => clearTimeout(timeoutId)
  }, [activeHeroIndex, images.length, HERO_IMAGE_ROTATION])

  const searchResults = useMemo(
    () => ({
      destinations: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName.destinations]?.destinations,
      products: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName.products]?.products,
    }),
    [data]
  )

  const valueProps = useMemo(() => {
    if (!isMobileView) return items

    // re arrange value props for mobile view based on design
    const valuePropsToReArrange = [...items]
    const siaValueProp = valuePropsToReArrange.splice(1, 1)
    valuePropsToReArrange.splice(0, 0, siaValueProp[0])
    return valuePropsToReArrange
  }, [items, isMobileView])

  const hideSeeMore =
    !Object.values(searchResults).reduce((total, item = []) => item.length + total) && searching

  const onSearchAutoSuggestOptionClick = (item: any, type?: string) => {
    if (type !== 'query') return
    window?.dataLayer?.push({
      event: DATA_LAYER_EVENT.SEARCH,
      [DATA_LAYER_EVENT.ECOMMERCE.SEARCH_TERM]: item,
    })
  }

  return (
    <div id={id} className={cn(s.container, s.offsetTop)}>
      <div ref={scrollStartWayPoint} className={s.scrollStartWayPoint} />

      <div className={s.heroImage}>
        {images.map((image: any, index: number) => (
          <Image
            key={index}
            src={image}
            layout="fill"
            alt="hero image"
            lazyLoad={index > 0}
            delayLoad={index > 0 ? DELAY_HERO_IMAGE_LOAD * index : undefined}
            className={cn({ [s.active]: activeHeroIndex === index })}
          />
        ))}
      </div>

      <Container className={s.body}>
        <div className={s.headerSection}>
          <h1 className={cn('text-white', s.header)}>{header}</h1>
          {subHeader && <h3 className={cn('text-white', s.subHeader)}>{subHeader}</h3>}
        </div>
        <div className={s.searchInputWrapper}>
          <SearchAutocomplete
            value={query}
            actAsButton={isMobileView}
            hideButton={false}
            hasSearchIcon={false}
            noInputBorder
            onClick={handleSearchClick}
            variant="white-rounded"
            size="large"
            placeholder={
              isMobileView ? (
                t('action.searchForDestinationsOrActivities')
              ) : (
                <TermPlaceholder search={search} />
              )
            }
            onChange={doSearch}
            onSubmit={() => router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query }))}
            trackEvent={trackEvent}
            initialStateComponent={
              <InitDropdown>
                <InitDropdown.RecentSearches onSelect={onSearchAutoSuggestOptionClick} />
                <InitDropdown.TrendingSearches onSelect={onSearchAutoSuggestOptionClick} />
              </InitDropdown>
            }
            loading={isMobileView && loader}
          >
            <SearchAutocompleteResults loading={searching} options={searchResults} className="mt-2" />
            <SearchAutocompleteSeeMore
              value={query}
              onClick={() => router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query }))}
              hidden={hideSeeMore}
              trackEvent={trackEvent}
              flatButton={!searchResults.destinations?.length && !searchResults.products?.length}
            />
          </SearchAutocomplete>
          <div ref={searchInputWayPoint} className={s.searchInputWayPoint} />
        </div>
        <BookingWithUs variant="home" className={s.bookWithUs} componentContent={{ items: valueProps }} />
      </Container>
      <ExposureTracker
        onExposure={() => {
          trackEvent?.({
            attributeId: componentEventId,
            attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
            eventType: EVENTS.TYPE.EXPOSURE,
          })
        }}
      />
      <SearchModal
        open={isSearchModalOpen}
        closeModal={closeSearchModal}
        initStateCompType="destination"
        onChange={doSearch}
        query={query}
        data={data}
        loading={searching}
        dontTrackOnSubmit
        onSubmit={(query: string) => {
          trackEvent?.({
            attributeId: EVENTS.AUTO_SUGGEST_SEARCH_INPUT,
            attributeType: EVENTS.ATTRIBUTES_TYPE.INPUT,
            isCompact: false,
            attributeValue: {
              query,
            },
          })
          query && router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query }))
        }}
        trackEvent={trackEvent}
      />
    </div>
  )
}

export { Hero }
