import React from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Image } from 'ui/image'

import { DynamicComponentProps } from 'components/dynamic-components/types'
import { ExposureTracker } from 'components/exposure-tracker'
import MainLayout from 'components/layout/main-layout'
import { Link } from 'components/link'

import { buildPath } from 'lib/utils'
import { useGlobalContext } from 'lib/context/global-context'

import { DESTINATION_ROUTE_V1 } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

interface DestinationsProps extends DynamicComponentProps {
  onButtonClick: () => void
  loading?: boolean
  className?: string
  size?: 'large' | 'medium' | 'small'
  trackEvent?: TrackEventType
}

interface DestinationCard {
  destinationId: string
  name: string
  uri: string
  rank?: number
  thumbnail: MediaItem
  code: string
}

const Destinations = ({
  componentQueryData = {},
  componentContent = {},
  componentEventId,
  className,
  size = 'medium',
  trackEvent,
  onButtonClick,
}: DestinationsProps) => {
  const { header, link } = componentContent
  const { destinations } = componentQueryData
  const { isMobileView } = useGlobalContext()

  const onAllDestinationClick = () => {
    trackEvent?.({
      attributeId: EVENTS.DESTINATIONS_SHOW_ALL,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })
    onButtonClick?.()
  }

  const renderExposureTracker = (index: number) => {
    let trackItems = destinations // desktop
    if (isMobileView) {
      // note: when items per row changed for mobile view, update below condition also
      if ((index + 1) % 2 === 0) {
        trackItems = destinations.slice(index - 1, index + 1) // mobile
      } else {
        return
      }
    } else if (index !== 0) {
      return
      // only allow to trigger for index 0 in dekstop as all cards in single row
    }
    return (
      <ExposureTracker
        onExposure={() => {
          trackEvent?.({
            attributeId: componentEventId,
            attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
            eventType: EVENTS.TYPE.EXPOSURE,
            attributeValue: {
              cardType: EVENTS.DESTINATION,
              cards: trackItems?.map(({ destinationId, rank }: DestinationCard, cardIndex: number) => ({
                destinationId,
                rank,
                cardIndex,
              })),
            },
          })
        }}
      />
    )
  }

  return (
    <MainLayout.Content className={cn(s.container, className)} whiteBg>
      <div className={s.header}>
        {(typeof header === 'string' ? [header] : header)?.map((text: string) => (
          <span key={text}>{text}</span>
        ))}
      </div>
      <div className={s.row}>
        {destinations?.map(
          ({ destinationId, name, uri, thumbnail, code }: DestinationCard, index: number) => {
            return (
              <Link
                passHref
                href={buildPath(DESTINATION_ROUTE_V1, {
                  destinationId: uri,
                  destinationCode: `${code}`,
                })}
                key={destinationId}
              >
                <a
                  className={cn(s.card, s[size])}
                  onClick={() => {
                    trackEvent?.({
                      attributeId: componentEventId,
                      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
                      attributeValue: {
                        cardIndex: index,
                        cardType: EVENTS.DESTINATION,
                        destinationId,
                      },
                    })
                  }}
                >
                  <Image
                    src={thumbnail || {}}
                    {...(isMobileView ? { aspectRatio: 3 / 4 } : { layout: 'fill' })}
                    size="medium"
                    lazyLoadHiddenPoint
                    alt={name}
                  />
                  <div className={s.placeholder} />
                  <h4 className={s.destination}>{name}</h4>
                  {renderExposureTracker(index)}
                </a>
              </Link>
            )
          }
        )}
        {/* for desktop at single row level
        {renderExposureTracker()} */}
      </div>
      {link?.label && (
        <div className={cn('flex-center', s.cta)}>
          <Button
            size={isMobileView ? 'medium' : 'large'}
            fluid={isMobileView}
            variant="tertiary-outline"
            onClick={onAllDestinationClick}
          >
            {link?.label}
          </Button>
        </div>
      )}
    </MainLayout.Content>
  )
}

export { Destinations }
